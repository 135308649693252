/* This is directly imported into the homeServiceIcon.css file and is only for that file at this time. */

.rowStyle {
    display: flex;
    justify-content: center;
    align-items: center;
}

.cellStyle {
    display: flex;
    align-items: center;
    padding: 5px 10px
}

@media screen and (max-width: 670px) {
    .rowStyle {
        flex-direction: column;
    }
    .cellStyle {
        display: flex;
        align-items: center;
    }
}