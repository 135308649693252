input, textarea {
    margin: 5px 5px 20px;
    width: 90%
}

form {
    max-width: 500px;
    margin: auto;
    padding-top: 50px;
}

.hidden {
    display: none;
}

input[type=submit] {
    background: #4294F3;
    color: white;
    font-size: 14px;
    padding: 15px 40px;
    border: none;
    border-radius: 30px;
}