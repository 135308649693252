table.docs {
    border: 1px solid black;
    margin: 0 auto;
    border-collapse: collapse;
}

table.docs > thead > tr > th {
    color: white;
    background-color: #4294f3;
    padding: 0px 10px;
}

table.docs > tbody > tr > td {
    padding: 3px 6px;
    text-align: center;
    background-color: #4294f340;
}

table.docs > tbody > tr:hover {
    background-color: #4294f3BF;
    color: aliceblue;
}

table.docs > tbody > td:hover {
    background-color: #4294f3BF;
 }

 @media screen and (max-width: 800px) {
    table.docs {
        font-size: 12px;
    }
}

@media screen and (max-width: 400px) {
    table.docs {
        font-size: 8px;
    }
}
