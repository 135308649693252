button {
    cursor: pointer;
}

.legalButtonWrapper {
    width: 100%;
    max-width: 1200px;
    margin: auto;
    display: flex;
    justify-content: center;
    padding-top: 75px;
}

.legalButton {
    width: 20%;
    margin: 25px;
    padding: 15px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #4294f340;
    font-weight: 800;
    font-size: 20px;
    box-shadow: 1px 1px 0 1px black;
    text-decoration: none;
    color: black
}

@media screen and (max-width: 1200px) {
    .legalButton {
        width: 25%;
    }
}

@media screen and (max-width: 950px) {
    .legalButtonWrapper {
        flex-direction: column;
        align-items: center;
    }
    .legalButton {
        width: 250px;
    }
}