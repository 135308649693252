/* This is directly imported into the homeBulletPoints.css file and is only for that file at this time. */

.header {
    color: #2196f3;
    font-size: 48px;
    font-weight: 800;
    padding-bottom: 40px;
    width: 75%;
}

.subHeader {
    color: #808080;
    font-size: 20px;
    font-weight: 300;
    padding-bottom: 40px;
    width: 75%;
}

.bullet {
    height: 8px;
    width: 8px;
    background-color: #4294F3;
    border-radius: 50%;
    margin: 7px 5px 0px 0px;
}

.bulletText {
    color: #808080;
    font-size: 18px;
    font-weight: 300;
    padding-bottom: 30px;
    width: 75%;
    flex: initial;
}

@media screen and (max-width: 800px) {
    .header {
        font-size: 24px;
    }

    .subHeader {
        font-size: 18px;
    }

    .bullet {
        height: 6px;
        width: 6px;
    }

    .bulletText {
        font-size: 12px;
    }
}
