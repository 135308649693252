@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

table.docs {
    border: 1px solid black;
    margin: 0 auto;
    border-collapse: collapse;
}

table.docs > thead > tr > th {
    color: white;
    background-color: #4294f3;
    padding: 0px 10px;
}

table.docs > tbody > tr > td {
    padding: 3px 6px;
    text-align: center;
    background-color: #4294f340;
}

table.docs > tbody > tr:hover {
    background-color: #4294f3BF;
    color: aliceblue;
}

table.docs > tbody > td:hover {
    background-color: #4294f3BF;
 }

 @media screen and (max-width: 800px) {
    table.docs {
        font-size: 12px;
    }
}

@media screen and (max-width: 400px) {
    table.docs {
        font-size: 8px;
    }
}

button {
    cursor: pointer;
}

.legalButtonWrapper {
    width: 100%;
    max-width: 1200px;
    margin: auto;
    display: flex;
    justify-content: center;
    padding-top: 75px;
}

.legalButton {
    width: 20%;
    margin: 25px;
    padding: 15px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #4294f340;
    font-weight: 800;
    font-size: 20px;
    box-shadow: 1px 1px 0 1px black;
    text-decoration: none;
    color: black
}

@media screen and (max-width: 1200px) {
    .legalButton {
        width: 25%;
    }
}

@media screen and (max-width: 950px) {
    .legalButtonWrapper {
        flex-direction: column;
        align-items: center;
    }
    .legalButton {
        width: 250px;
    }
}
* {
  font-family: Inter,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
  overflow-x: hidden;
  position: relative;
}

button {
  background: #4294F3;
  color: white;
  font-size: 14px;
  padding: 15px 40px;
  border: none;
  border-radius: 15px;
}

 /*Button hover state*/
  button:hover {
  background: #4294F3;
    }

button_pricing {
  background: #4294F3;
  color: black;
  font-size: 14px;
  padding: 15px 40px;
  border: none;
  border-radius: 15px;
}

 /*Button_pricing hover state*/
  button:hover {
  background: #4294F3;
    }

header {
  position: fixed;
  width: 100%;
  z-index: 10;
  overflow-y: hidden; 	
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

header > div {
  height: 65px;
  padding: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
	  overflow-y: hidden;
}

header > div > a {
  color: #4294F3;
  padding-right: 50px;
  text-decoration: none;
  font-weight: 700;
  font-size: 24px;
  white-space: nowrap;
  overflow-y: hidden;
}

header > div > a > img {
  height: 55px;
  overflow-y: hidden;
}

.about {
  font-weight: 800;
  min-height: 100vh;
}

.aboutContentWrapper {
  display: flex;
  flex-direction: column;
  grid-gap: 40px;
  gap: 40px;
  max-width: 1000px;
  padding-top: 40px;
  margin-left: auto;
  margin-right: auto;
}

.aboutTeamMember {
  text-align: center;
  width: 24%;
}

.aboutTeamMemberTitle {
  font-weight: 800;
  text-align: center;
  width: 24%;
}

.aboutTeamWrapper {
  display: flex;
  justify-content: space-evenly;
}

.aboutTextLine {
  font-size: 20px;
  font-weight: 300;
}

.clear {
  clear: both;
  height: 50px;
  opacity: 0;
}

.footer  {
 /* background: #E2E2E2;*/
  background: #ffffff;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  text-decoration: none;
  justify-content: space-around;
  align-items: center;
  font-weight: 500;
}

.footer > div {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.footer > div > a {
  color: black;
  text-decoration: none;
}

.footer > div > a > img {
  height: 35px;
  overflow-y: hidden;
}

.fullScreen {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;

}

.fullScreen > div {
  width: 60%;
  margin: auto;
  padding: 60px;
  left: 5%;	
}

.fullScreen > div > h3 {
  color: #2196f3;
  font-size: 48px;
  font-weight: 800;
  letter-spacing: 1px;
  width: 75%;
}

.fullScreen > div > h4 {
  color: #808080;
  font-size: 24px;
  font-weight: 800;
  letter-spacing: 1px;
  width: 75%;
}

.fullScreen > div > h6 {
  color: #808080;
  font-size: 18px;
  font-weight: 400;
  width: 75%;
}

.fullScreen2 {
  width: 100%;
  display: flex;
  align-items: top;
  justify-content: space-evenly;

}

.fullScreen2 > div {
  width: 60%;
  margin: auto;
  align: top;
  left: 5%;
}

.fullScreen2 > div > h3 {
  color: #2196f3;
  font-size: 48px;
  font-weight: 800;
  letter-spacing: 1px;
  width: 75%;
}

.fullScreen2 > div > h4 {
  color: #808080;
  font-size: 26px;
  font-weight: 800;
  letter-spacing: 1px;
  width: 75%;
  align: top;
}

.fullScreen2 > div > h6 {
  color: #808080;
  font-size: 18px;
  font-weight: 400;
  width: 75%;
}

.headerGap {
  height: 105px;
}

.hero {
  position: relative;
  max-width: 100%;
  height: 100vh;
  overflow-y: hidden;
  z-index: 0;
  background-size: cover;
  background-image: linear-gradient(to right, #00000095, #00000075), 
    url(/static/media/hero_90s.337130cd.jpg);
}

.hero-ACADA {
  background-image: linear-gradient(to right, #00000095, #00000075), 
    url(/static/media/ACADATV.f7fbc48b.png);
}

.hero-cities {
  background-image: linear-gradient(to right, #00000095, #00000075), 
    url(/static/media/TownArealWithRiver.5ee76081.jpg);
}

.hero-camp {
  background-image: linear-gradient(to right, #00000095, #00000075), 
    url(/static/media/UniversityText.ae7bef27.jpg);
}

.hero-prop {
  background-image: linear-gradient(to right, #00000095, #00000075), 
    url(/static/media/forrent.9a9cdc95.jpg);
}

.hero-rah {
  background-image: linear-gradient(to right, #00000095, #00000075), 
    url(/static/media/frustratedphonecall.3b3aa692.jpg);
}

.hero-overlay2 {
  width: 65%;
  left: 15%;
  top: 30%;

}

.hero-overlay {
  position: absolute;
  width: 65%;
  left: 15%;
  top: 40%;
  color: white;
}

.hero-overlay > h3 {
  font-size: 48px;
  margin: auto;
  font-weight: 800;
  letter-spacing: 1px;
}

.hero-overlay > h6 {
  font-size: 24px;
  width: 50%;
  font-weight: 600;
}

.homeBulletPointsWrappper {
  width: 40%;
  margin: auto;
  padding: 60px
}

.homePageCaption {
  text-align: center;
  font-size: 36px;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.homePageCenterImage {
  width: 30vw;
  height: 22.5vw;
  display: block;
  border-radius: 25% 10%;
  padding-bottom: 50px
}

.homePageRightImage {
  width: 30vw;
  height: 22.5vw;
  display: block;
  padding: 60px;
  right: 5%;	
  border-radius: 20% / 50%;
}

.homePageImages {
  width: 40vw;
  padding: 40px;
  border-radius: 40px;
  align-items: center;
  margin: auto;
  display: block;

}

.iconsHeader {
  font-size: 48px;
  font-weight: 800;
  letter-spacing: 1px;
  color: #4294F3;
  width: 100%;
  text-align: center;
  padding: 40px 0px;
}

.iconsHeader2 {
  font-size: 48px;
  font-weight: 800;
  letter-spacing: 1px;
  color: #4294F3;
  width: 100%;
  text-align: center;
  padding: 40px 0px 10px 0px;
}

.scrollHeader {
  background-color: #FFFFFF00;
  transition-duration: 0.5s;
}

.scrollHeader.white{
  background-color: #FFFFFF;
  transition-duration: 0.5s;
}

.subHeaderText {
  position: absolute;
  top: 40%;
  right: 15%;
  font-size: 36px;
  font-weight: 800;
}

h1a {
  display: flex;
  align-items: left;
  overflow: hidden;
  height: 65px;
}

h1a span {
  font-size: 48px;
  overflow: hidden;
  margin-left: 5px;
  color:white;
  display: inline-block;
}

ul {
  list-style-type: none;
  margin: 0;
  padding-left: 15px;
  padding-bottom: 20px;
  padding-right: 10px;
}

.wordCarousel {
    font-size: 48px;
    font-weight: 800;
    color:#4294F3;
    overflow: hidden;
    div {
        position: relative;
        float: right;
        height: 65px;
        padding-top: 10px;
        margin-top: -10px;
	color: #4294F3;
        font-family: tahoma;
        font-weight: 800;
        padding: 5 0px;
        margin-bottom: 45px;
        display: block;
	}   
}

.flip2 { -webkit-animation: flip2 6s cubic-bezier(0.23, 1, 0.32, 1.2) infinite; animation: flip2 6s cubic-bezier(0.23, 1, 0.32, 1.2) infinite; }
.flip3 { -webkit-animation: flip3 8s cubic-bezier(0.23, 1, 0.32, 1.2) infinite; animation: flip3 8s cubic-bezier(0.23, 1, 0.32, 1.2) infinite; }
.flip4 { -webkit-animation: flip4 10s cubic-bezier(0.23, 1, 0.32, 1.2) infinite; animation: flip4 10s cubic-bezier(0.23, 1, 0.32, 1.2) infinite; }
.flip5 { -webkit-animation: flip5 12s cubic-bezier(0.23, 1, 0.32, 1.2) infinite; animation: flip5 12s cubic-bezier(0.23, 1, 0.32, 1.2) infinite; }

@-webkit-keyframes flip2 {
    0% { margin-top: -180px; }
    5% { margin-top: -90px;  }
    50% { margin-top: -90px; }
    55% { margin-top: 0px; }
    99.99% { margin-top: 0px; }
    100% { margin-top: -180px; }
}

@keyframes flip2 {
    0% { margin-top: -180px; }
    5% { margin-top: -90px;  }
    50% { margin-top: -90px; }
    55% { margin-top: 0px; }
    99.99% { margin-top: 0px; }
    100% { margin-top: -180px; }
}

@-webkit-keyframes flip3 {
    0% { margin-top: -270px; }
    5% { margin-top: -180px; }
    33% { margin-top: -180px; }
    38% { margin-top: -90px; }
    66% { margin-top: -90px; }
    71% { margin-top: 0px; }
    99.99% { margin-top: 0px; }
    100% { margin-top: -270px; }
}

@keyframes flip3 {
    0% { margin-top: -270px; }
    5% { margin-top: -180px; }
    33% { margin-top: -180px; }
    38% { margin-top: -90px; }
    66% { margin-top: -90px; }
    71% { margin-top: 0px; }
    99.99% { margin-top: 0px; }
    100% { margin-top: -270px; }
}

@-webkit-keyframes flip4 {
    0% { margin-top: -360px; }
    5% { margin-top: -270px; }
    25% { margin-top: -270px; }
    30% { margin-top: -180px; }
    50% { margin-top: -180px; }
    55% { margin-top: -90px; }
    75% { margin-top: -90px; }
    80% { margin-top: 0px; }
    99.99% { margin-top: 0px; }
    100% { margin-top: -360px; }
}

@keyframes flip4 {
    0% { margin-top: -360px; }
    5% { margin-top: -270px; }
    25% { margin-top: -270px; }
    30% { margin-top: -180px; }
    50% { margin-top: -180px; }
    55% { margin-top: -90px; }
    75% { margin-top: -90px; }
    80% { margin-top: 0px; }
    99.99% { margin-top: 0px; }
    100% { margin-top: -360px; }
}

@-webkit-keyframes flip5 {
    0% { margin-top: 0px; }
    10% { margin-top: 0px; }
    25% { margin-top: -58px; }
    40% { margin-top: -58px; }
    50% { margin-top: -115px; }
    60% { margin-top: -115px; }
    70% { margin-top: -172px; }
    80% { margin-top: -172px; }
    90% { margin-top: -230px; }
    100% { margin-top: -230px; }
}

@keyframes flip5 {
    0% { margin-top: 0px; }
    10% { margin-top: 0px; }
    25% { margin-top: -58px; }
    40% { margin-top: -58px; }
    50% { margin-top: -115px; }
    60% { margin-top: -115px; }
    70% { margin-top: -172px; }
    80% { margin-top: -172px; }
    90% { margin-top: -230px; }
    100% { margin-top: -230px; }
}

#reverseOrder {
  flex-direction: row-reverse;
}


/* First step down to 1800px*/
@media screen and (max-width: 1800px) {
   
.hero-overlay > h3 {
    font-size: 32px;
    margin: 1px;
}
	
}

/* Second step down to 1400px*/
@media screen and (max-width: 1400px) {
 
.iconsHeader2 {
  font-size: 28px;
  font-weight: 800;
  letter-spacing: 1px;
  color: #4294F3;
  width: 100%;
  text-align: center;
  padding: 40px 0px 10px 0px;
}

.hero-overlay > h3 {
    font-size: 28px;
    margin: 1px;
  }

h1a {
  display: flex;
  align-items: left;
  overflow: hidden;
  height: 38px;
}

h1a span {
  font-size: 32px;
  overflow: hidden;
  margin-left: 5px;
  color:white;
  display: inline-block;
}
	
.wordCarousel {
    font-size: 32px;
    font-weight: 800;
    color:#4294F3;
    overflow: hidden;
    div {
        position: relative;
        float: right;
        height: 45px;
        padding-top: 10px;
        margin-top: -10px;
	color: #4294F3;
        font-family: tahoma;
        font-weight: 800;
        padding: 5 0px;
        margin-bottom: 45px;
        display: block;
	}   
}	
  .footer {
    font-size: 12px;
  }

  .footer > div > a > img {
  height: 15px;
  overflow-y: hidden;
}
	
  header > div > a {
    font-size: 20px;
  }

  header > div > a > img {
    height: 36px
  }

  .headerGap {
    height: 75px;
  }
	
@-webkit-keyframes flip5 {
    0% { margin-top: 0px; }
    10% { margin-top: 0px; }
    25% { margin-top: -39px; }
    40% { margin-top: -39px; }
    50% { margin-top: -78px; }
    60% { margin-top: -78px; }
    70% { margin-top: -116px; }
    80% { margin-top: -116px; }
    90% { margin-top: -156px; }
    100% { margin-top: -156px; }
}
	
@keyframes flip5 {
    0% { margin-top: 0px; }
    10% { margin-top: 0px; }
    25% { margin-top: -39px; }
    40% { margin-top: -39px; }
    50% { margin-top: -78px; }
    60% { margin-top: -78px; }
    70% { margin-top: -116px; }
    80% { margin-top: -116px; }
    90% { margin-top: -156px; }
    100% { margin-top: -156px; }
}
	
}

/* Third step down to 1200px*/
@media screen and (max-width: 1200px) {
 
  .aboutContentWrapper {
    max-width: 800px;
  }
  
  .aboutTextLine {
    margin: 0 25px;
    font-size: 14px;
  }

  .aboutTeamMember {
    width: 50%;
  }

  .aboutTeamWrapper {
    flex-wrap: wrap;
  }

  .iconsHeader2 {
  	font-size: 28px;
  	font-weight: 800;
  	letter-spacing: 1px;
  	color: #4294F3;
  	width: 100%;
  	text-align: center;
  	padding: 40px 0px 10px 0px;
   }
	
  .hero-overlay {
    width: 75%;
  }


  .hero-overlay > h3 {
    font-size: 28px;
    margin: 8px;
  }

  .hero-overlay > h6 {
    font-size: 20px;
    margin: 8px;
  }

h1a {
  display: flex;
  align-items: left;
  overflow: hidden;
  height: 36px;
}

h1a span {
  font-size: 28px;
  overflow: hidden;
  margin-left: 5px;
  color:white;
  display: inline-block;
}
	
  .wordCarousel {
    font-size: 28px;
    font-weight: 800;
    color:#4294F3;
    overflow: hidden;
    div {
        position: relative;
        float: right;
        height: 36px;
        padding-top: 10px;
        margin-top: -10px;
	color: #4294F3;
        font-family: tahoma;
        font-weight: 800;
        padding: 5 0px;
        margin-bottom: 45px;
        display: block;
	}   
  }	
	
  @-webkit-keyframes flip5 {
    0% { margin-top: 0px; }
    10% { margin-top: 0px; }
    25% { margin-top: -36px; }
    40% { margin-top: -36px; }
    50% { margin-top: -70px; }
    60% { margin-top: -70px; }
    70% { margin-top: -102px; }
    80% { margin-top: -102px; }
    90% { margin-top: -136px; }
    100% { margin-top: -136px; }
  }	
	
  @keyframes flip5 {
    0% { margin-top: 0px; }
    10% { margin-top: 0px; }
    25% { margin-top: -36px; }
    40% { margin-top: -36px; }
    50% { margin-top: -70px; }
    60% { margin-top: -70px; }
    70% { margin-top: -102px; }
    80% { margin-top: -102px; }
    90% { margin-top: -136px; }
    100% { margin-top: -136px; }
  }	
	
  .fullScreen {
    height: auto;
    left: 1%;
  }

  .fullScreen > div > h3 {
    font-size: 32px;
  }

  .fullScreen > div > h6 {
    font-size: 18px;
  }

 .fullScreen2 {
    height: auto;
    left: 1%;
  }

  .fullScreen2 > div > h3 {
    font-size: 32px;
  }
  
  .fullScreen2 > div > h4 {
    font-size: 20px;
  }

  .fullScreen2 > div > h6 {
    font-size: 18px;
  }

  .homePageCaption {
    font-size: 18px;
  }
	
  .homePageCenterImage {
  	width: 28vw;
  	display: block;
  	border-radius: 25% 10%;
  	padding-bottom: 30px
  }
	
  .subHeaderText {
    font-size: 24px;
  }
  

}

/* 4th step down to 800px*/
@media screen and (max-width: 800px) {
  

  .animated {
    transition-property: none !important;
    -webkit-transform: none !important;
            transform: none !important;
    -webkit-animation: none !important;
            animation: none !important;
  }
	
  .aboutTeamMember {
    width: auto;
    padding-bottom: 100px;
  }

  .aboutTeamWrapper {
    display: block;
  }

  .footer {
    font-size: 6px;
  }
	
  .footer > div > a > img {
  height: 10px;
  overflow-y: hidden;
  }
	
  .iconsHeader2 {
  	font-size: 16px;
  	font-weight: 800;
  	letter-spacing: 1px;
  	color: #4294F3;
  	width: 100%;
  	text-align: center;
  	padding: 40px 0px 10px 0px;
   }
	
  .hero-overlay > h3 {
    font-size: 16px;
    margin: 8px;
  }

  .hero-overlay > h6 {
    font-size: 12px;
    margin: 8px;
  }

  h1a {
  	display: flex;
  	align-items: left;
 	 overflow: hidden;
  	height: 20px;
  }

  h1a span {
  	font-size: 16px;
  	overflow: hidden;
  	margin-left: 5px;
  	color:white;
  	display: inline-block;
  }
	
	.wordCarousel {
    font-size: 16px;
    font-weight: 800;
    color:#4294F3;
    overflow: hidden;
    div {
        position: relative;
        float: right;
        height: 20px;
        padding-top: 10px;
        margin-top: -10px;
	color: #4294F3;
        font-family: tahoma;
        font-weight: 800;
        padding: 5 0px;
        margin-bottom: 45px;
        display: block;
	}   
  }	

	
  .fullScreen {
    flex-direction: column;
    height: auto;
  }

  .fullScreen > div {
    width: 90%;
    padding: 5px;
  }

  .fullScreen > div > div {
    padding: 10px;
  }

  .fullScreen > div > h3 {
    font-size: 24px;
  }

  .fullScreen > div > h6 {
    font-size: 16px;
  }

  .fullScreen > div > button {
    display: block;
    margin: 0 auto
  }

  .fullScreen2 {
    flex-direction: column;
    height: auto;
  }

  .fullScreen2 > div {
    width: 90%;
    padding: 5px;
  }

  .fullScreen2 > div > div {
    padding: 10px;
  }

  .fullScreen2 > div > h3 {
    font-size: 24px;
  }
  .fullScreen2 > div > h4 {
    font-size: 20px;
  }

  .fullScreen2 > div > h6 {
    font-size: 16px;
  }

  .fullScreen2 > div > button {
    display: block;
    margin: 0 auto
  }	
	
  header > div {
    padding: 5px 10px;
  }

  header > div > a {
    font-size: 16px;
  }

  header > div > a > img {
    height: 22px
  }

  .headerGap {
    height: 50px;
  }

  .homePageCaption {
    font-size: 12px;
  }
	
  .homePageCenterImage {
  	width: 25vw;
  	display: block;
  	border-radius: 25% 10%;
  	padding-bottom: 50px
  }
	
  .homePageImages {
    width: 55vw;
    object-fit: cover;
    display: block;
    margin: 0 auto
  }

  .iconsHeader {
    font-size: 28px;
  }

  .scrollHeader > img {
    max-width: 50%;
    height: auto;
  }

  .scrollHeader > div {
    font-size: 16px;
  }

  #streetLightsServicePhoto, #checkDonePhoto {
    height: auto;
  }

  .subHeaderText {
    font-size: 18px;
  }

  #reverseOrder {
    flex-direction: column;
    height: auto;
  } 


  .fullScreen > div > h3 {
    font-size: 16px;
  }

  .fullScreen > div > h6 {
    font-size: 12px;
  }

  .fullScreen2 {
    height: auto;
    left: 1%;
  }

  .fullScreen2 > div > h3 {
    font-size: 16px;
  }
  
  .fullScreen2 > div > h4 {
    font-size: 14px;
  }

  .fullScreen2 > div > h6 {
    font-size: 8px;
  }
	
  @-webkit-keyframes flip5 {
    0% { margin-top: 0px; }
    10% { margin-top: 0px; }
    25% { margin-top: -19px; }
    40% { margin-top: -19px; }
    50% { margin-top: -38px; }
    60% { margin-top: -38px; }
    70% { margin-top: -57px; }
    80% { margin-top: -57px; }
    90% { margin-top: -76px; }
    100% { margin-top: -76px; }
  }
	
  @keyframes flip5 {
    0% { margin-top: 0px; }
    10% { margin-top: 0px; }
    25% { margin-top: -19px; }
    40% { margin-top: -19px; }
    50% { margin-top: -38px; }
    60% { margin-top: -38px; }
    70% { margin-top: -57px; }
    80% { margin-top: -57px; }
    90% { margin-top: -76px; }
    100% { margin-top: -76px; }
  }
}


/* Fifth step down to 600px*/
@media screen and (max-width: 600px) {
  
  header > div > a {
    font-size: 9px;
  }

  .iconsHeader2 {
  	font-size: 16px;
  	font-weight: 800;
  	letter-spacing: 1px;
  	color: #4294F3;
  	width: 100%;
  	text-align: center;
  	padding: 40px 0px 10px 0px;
  }
	
  .iconsHeader {
  	font-size: 16px;
 	 font-weight: 800;
  	letter-spacing: 1px;
  	color: #4294F3;
  	width: 100%;
  	text-align: center;
  	padding: 40px 0px;
  }	
	
 .hero-image {
    width: auto;
    height: 75vh;
  }
	
  .hero-overlay > h3 {
    font-size: 10px;
    margin: 8px;
  }

  .hero-overlay > h6 {
    font-size: 8px;
    margin: 8px;
  }
	
h1a {
  display: flex;
  align-items: left;
  overflow: hidden;
  height: 12px;
}

h1a span {
  font-size: 10px;
  overflow: hidden;
  margin-left: 5px;
  color:white;
  display: inline-block;
}
	
.wordCarousel {
    font-size: 10px;
    font-weight: 800;
    color:#4294F3;
    overflow: hidden;
    div {
        position: relative;
        float: right;
        height: 12px;
        padding-top: 10px;
        margin-top: -10px;
	color: #4294F3;
        font-family: tahoma;
        font-weight: 800;
        padding: 5 0px;
        margin-bottom: 45px;
        display: block;
	}   
}	
	
@-webkit-keyframes flip5 {
    0% { margin-top: 0px; }
    10% { margin-top: 0px; }
    25% { margin-top: -13px; }
    40% { margin-top: -13px; }
    50% { margin-top: -26px; }
    60% { margin-top: -26px; }
    70% { margin-top: -39px; }
    80% { margin-top: -39px; }
    90% { margin-top: -52px; }
    100% { margin-top: -52px; }
}	
	
@keyframes flip5 {
    0% { margin-top: 0px; }
    10% { margin-top: 0px; }
    25% { margin-top: -13px; }
    40% { margin-top: -13px; }
    50% { margin-top: -26px; }
    60% { margin-top: -26px; }
    70% { margin-top: -39px; }
    80% { margin-top: -39px; }
    90% { margin-top: -52px; }
    100% { margin-top: -52px; }
}
  .homePageCenterImage {
  	width: 20vw;
  	display: block;
  	border-radius: 25% 10%;
  	padding-bottom: 20px
  }	
  .subHeaderText {
    font-size: 12px;
  }
}

/* This is directly imported into the homeBulletPoints.css file and is only for that file at this time. */

.header {
    color: #2196f3;
    font-size: 48px;
    font-weight: 800;
    padding-bottom: 40px;
    width: 75%;
}

.subHeader {
    color: #808080;
    font-size: 20px;
    font-weight: 300;
    padding-bottom: 40px;
    width: 75%;
}

.bullet {
    height: 8px;
    width: 8px;
    background-color: #4294F3;
    border-radius: 50%;
    margin: 7px 5px 0px 0px;
}

.bulletText {
    color: #808080;
    font-size: 18px;
    font-weight: 300;
    padding-bottom: 30px;
    width: 75%;
    flex: initial;
}

@media screen and (max-width: 800px) {
    .header {
        font-size: 24px;
    }

    .subHeader {
        font-size: 18px;
    }

    .bullet {
        height: 6px;
        width: 6px;
    }

    .bulletText {
        font-size: 12px;
    }
}

/* This is directly imported into the homeServiceIcon.css file and is only for that file at this time. */

.rowStyle {
    display: flex;
    justify-content: center;
    align-items: center;
}

.cellStyle {
    display: flex;
    align-items: center;
    padding: 5px 10px
}

@media screen and (max-width: 670px) {
    .rowStyle {
        flex-direction: column;
    }
    .cellStyle {
        display: flex;
        align-items: center;
    }
}
input, textarea {
    margin: 5px 5px 20px;
    width: 90%
}

form {
    max-width: 500px;
    margin: auto;
    padding-top: 50px;
}

.hidden {
    display: none;
}

input[type=submit] {
    background: #4294F3;
    color: white;
    font-size: 14px;
    padding: 15px 40px;
    border: none;
    border-radius: 30px;
}
